// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-my-files-js": () => import("./../../../src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-templates-achievement-page-js": () => import("./../../../src/templates/achievement-page.js" /* webpackChunkName: "component---src-templates-achievement-page-js" */),
  "component---src-templates-all-achievement-page-js": () => import("./../../../src/templates/allAchievement-page.js" /* webpackChunkName: "component---src-templates-all-achievement-page-js" */),
  "component---src-templates-all-clip-page-js": () => import("./../../../src/templates/allClip-page.js" /* webpackChunkName: "component---src-templates-all-clip-page-js" */),
  "component---src-templates-base-pages-js": () => import("./../../../src/templates/base-pages.js" /* webpackChunkName: "component---src-templates-base-pages-js" */),
  "component---src-templates-clip-page-js": () => import("./../../../src/templates/clip-page.js" /* webpackChunkName: "component---src-templates-clip-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-topics-in-focus-js": () => import("./../../../src/templates/topicsInFocus.js" /* webpackChunkName: "component---src-templates-topics-in-focus-js" */)
}

